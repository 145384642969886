import { takeLatest, call, put } from 'redux-saga/effects';
import { navigate } from '@reach/router';

import {
  getAcknowledgement,
  getAcknowledgements,
  postAcknowledgement,
  putAcknowledgement,
} from 'http/system';
import { actions as snackbarActions } from 'redux/snackbar';
import { actions } from './index';

function* fetchAcknowledgements({ payload }) {
  try {
    const result = yield call(getAcknowledgements, payload);

    yield put(actions.systemGetAcknowledgementsSuccess(result.data.acknowledgements));
  } catch (error) {
    yield put(actions.systemGetAcknowledgementsError({ data: error }));
  }
}

function* fetchAcknowledgement({ payload }) {
  try {
    const result = yield call(getAcknowledgement, payload);

    yield put(actions.systemGetAcknowledgementSuccess(result));
  } catch (error) {
    yield put(actions.systemGetAcknowledgementError({ data: error }));
  }
}

function* saveAcknowledgement({ payload }) {
  try {
    const result = yield call(postAcknowledgement, payload);

    yield put(actions.systemSaveAcknowledgementSuccess(result));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Assessment saved' } }));

    navigate('/system/acknowledgements');
  } catch (error) {
    yield put(actions.systemSaveAcknowledgementError(error));
  }
}

function* updateAcknowledgement({ payload }) {
  try {
    const result = yield call(putAcknowledgement, payload);

    yield put(actions.systemUpdateAcknowledgementSuccess(result));
    yield put(snackbarActions.showSnackbar({ data: { type: 'success', message: 'Acknowledgement updated' } }));

    navigate('/system/acknowledgements');
  } catch (error) {
    yield put(actions.systemUpdateAcknowledgementError(error));
  }
}

export default function* sagas() {
  yield takeLatest(actions.systemGetAcknowledgements, fetchAcknowledgements);
  yield takeLatest(actions.systemGetAcknowledgement, fetchAcknowledgement);
  yield takeLatest(actions.systemSaveAcknowledgement, saveAcknowledgement);
  yield takeLatest(actions.systemUpdateAcknowledgement, updateAcknowledgement);
}
